import COLORS from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    backgroundColor: COLORS.kgGreen,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'auto',
    [QUERIES.desktop]: {
      minHeight: '80vh'
    }
  },
  content: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    [QUERIES.desktop]: {
      paddingLeft: 32,
      paddingBottom: 112
    }
  },
  formCard: {
    backgroundColor: COLORS.white,
    padding: '32px 16px',
    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
    width: 'auto',
    maxWidth: 'none',
    borderRadius: 0,
    [QUERIES.desktop]: {
      maxWidth: 600,
      borderRadius: 4,
      padding: 32
    }
  },
  title: {
    color: COLORS.offBlack,
    margin: 0,
    marginBottom: 32
  },
  input: {
    flex: 1,
    marginBottom: 16
  },
  button: {
    marginTop: 32,
    textTransform: 'none',
    color: 'white',
    backgroundColor: COLORS.hotPink,
    fontSize: 18,
    height: 50,
    paddingRight: 24,
    paddingLeft: 24,
    minWidth: 180
  },
  buttonDisabled: {
    opacity: 0.65
  },
  circularProgress: {
    color: COLORS.white
  },
  separator: {
    height: 90,
    [QUERIES.desktop]: {
      height: 120
    }
  },
  datePicker: {
    fontSize: 24,
    color: '#484848',
    marginBottom: 24,
    maxWidth: '100%',
    marginRight: 32
  },
  blurbP: {
    fontSize: 24,
    marginBottom: 0
  }
});

export default styles;
