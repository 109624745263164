import React from 'react';
import styles from './RemindMeLater.styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { isMobile } from 'react-device-detect';
import { css } from 'aphrodite';
import STYLES from '../../../styles/shared';

const RemindMeLater = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.separator)}/>
    <div className={css(styles.content)}>
      <div className={css(styles.formCard)}>
        <h1 className={css(styles.title)}>{props.title}</h1>
        {!props.customTitleUsed && <p style={{marginBottom: 4}}>Pickup date and time:</p>}

        {(!isMobile && !props.customTitleUsed) && <div className={css(STYLES.fullRow)}>
          <TextField
            id="date"
            type="date"
            value={props.pickupDate}
            InputProps={{
              className: css(styles.datePicker)
            }}
            onChange={props.changePickupDate}
            name={'pickupDate'}
          />
          <TextField
            id="time"
            type="time"
            value={props.pickupTime}
            InputProps={{
              className: css(styles.datePicker)
            }}
            onChange={props.changePickupTime}
            name={'pickupTime'}
          />
        </div>}

        {(isMobile && !props.customTitleUsed) && <TextField
          id="datetime-local"
          type="datetime-local"
          value={props.pickupDateAndTime}
          InputProps={{
            className: css(styles.datePicker)
          }}
          onChange={props.changePickupDateAndTime}
          name={'pickupDateAndTime'}
        />}

        <div className={css(STYLES.fullRow)}>
          <TextField
            error={props.showError}
            helperText={props.showError && 'Please enter your mobile number'}
            id="outlined-name"
            label="Mobile number"
            margin="normal"
            variant="outlined"
            className={css(styles.input)}
            onFocus={props.removeError}
            onChange={props.changePhone}
            type="number"
            name={'phone'}
            value={props.phone}
          />
        </div>

        <div className={css(STYLES.fullRow)}>
          <TextField
            id="outlined-name"
            label="Name"
            margin="normal"
            variant="outlined"
            className={css(styles.input)}
            onChange={props.changeFullName}
            name={'infoName'}
            value={props.name}
          />
        </div>

        <div className={css(STYLES.fullRow)}>
          <TextField
            id="outlined-name"
            label="Email"
            margin="normal"
            variant="outlined"
            className={css(styles.input)}
            onChange={props.changeEmail}
            type="email"
            name="infoEmail"
            autoComplete="email"
            value={props.email}
          />
        </div>

        {props.blurb && <p className={css(styles.blurbP)}>{props.blurb}</p>}

        <div className={css(STYLES.fullRow)}>
          <div style={{flex: 1}}/>
          <Button variant={'contained'} color={'primary'} disabled={props.userInfo.submitting} className={css(styles.button, props.userInfo.submitting && styles.buttonDisabled)} onClick={props.handleSubmit}>
            {!props.userInfo.submitting && (props.ctaText)}
            {props.userInfo.submitting && <CircularProgress className={css(styles.circularProgress)} size={20} thickness={6}/>}
          </Button>
        </div>
      </div>
    </div>
    <div style={{flex: 1}}/>
  </div>
);

export default RemindMeLater;
