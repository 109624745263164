import React from 'react';
import FeatureList from '../containers/sections/FeatureList/FeatureList';
import AppInterface from '../containers/sections/AppInterface/AppInterface';
import HowItWorksContainer from '../containers/sections/HowItWorks/HowItWorksContainer';
import PhoneBooking from '../containers/sections/PhoneBooking/PhoneBooking';
import CredibilitySection from '../containers/sections/Credibility/Credibility';
import RemindMeLaterContainer from '../containers/heroes/RemindMeLater/RemindMeLaterContainer';
import HelpLifting from '../containers/sections/HelpLifting/HelpLifting';
import COLORS from '../styles/colors';
import SwipeFeaturedReviews from '../containers/reviews/SwipeableReviews/SwipeableReviews';
import Layout from '../containers/gatsby/layout';

const RemindMeLaterPage = (props) => (
  <Layout location={props.location}>
    <RemindMeLaterContainer location={props.location} cta={'Remind me later'}/>
    <CredibilitySection/>
    <SwipeFeaturedReviews background={COLORS.white}/>
    <FeatureList/>
    <AppInterface/>
    <HelpLifting/>
    <HowItWorksContainer/>
    <PhoneBooking/>
  </Layout>
);

export default RemindMeLaterPage;
