import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import React, { Component } from 'react';
import RemindMeLater from './RemindMeLater';
import { leaveUserInfoForm, submitUserInfo } from './RemindMeLaterActions';
import Utils from '../../../library/utils';
import { navigate } from 'gatsby';

const moment = require('moment');
const qs = require('query-string');

const startingPickupDateAndTime = moment().add('3', 'hours').format('YYYY-MM-DDTHH:00');
const startingPickupDate = moment().add('3', 'hours').format('YYYY-MM-DD');
const startingPickupTime = moment().add('3', 'hours').format('HH:00');

class RemindMeLaterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.userInfo.name || '',
      phone: props.userInfo.phone || '',
      pickupDate: startingPickupDate,
      pickupTime: startingPickupTime,
      pickupDateAndTime: startingPickupDateAndTime,
      email: props.userInfo.email || '',
      showError: false,
      title: 'When do you need to move?',
      ctaText: props.cta || 'Get a quote',
      blurb: null,
      customTitleUsed: false
    }
  }

  componentDidMount() {
    const titleUrlParam = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).title;
    const ctaUrlParam = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).cta;
    const blurbUrlParam = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).blurb;

    titleUrlParam && this.setState({
      title: decodeURIComponent(titleUrlParam),
      customTitleUsed: true
    });
    ctaUrlParam && this.setState({
      ctaText: decodeURIComponent(ctaUrlParam)
    });
    blurbUrlParam && this.setState({
      blurb: decodeURIComponent(blurbUrlParam)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userInfo.submittedSuccess && !this.props.userInfo.submittedSuccess) {
      ReactPixel.track('Lead', {
        content_name: 'InitiateCheckout',
        content_category: 'Remind me later'
      });
      ReactGA.event({
        category: 'Remind me later',
        action: 'Form sent',
        label: 'RemindMeLaterComplete'
      });


      if (!Utils.isBlank(this.state.phone)) {
        if (this.state.customTitleUsed) {
          navigate('/book')
        } else {
          navigate('/book/location')
        }
      } else {
        this.props.leaveUserInfoForm();
        this.setState({
          showError: true
        })
      }
    }
  }

  changePickupDate = (e) => {
    this.setState({
      pickupDate: e.target.value,
      pickupDateAndTime: e.target.value + 'T' + this.state.pickupTime
    });
  };

  changePickupTime = (e) => {
    this.setState({
      pickupTime: e.target.value,
      pickupDateAndTime: this.state.pickupDate + 'T' + e.target.value
    });
  };

  changePickupDateAndTime = (e) => {
    const pickupDateAndTime = e.target.value;
    this.setState({
      pickupDateAndTime: pickupDateAndTime,
      pickupDate: pickupDateAndTime.substr(0, pickupDateAndTime.indexOf('T')),
      pickupTime: pickupDateAndTime.substr((pickupDateAndTime.indexOf('T') + 1), pickupDateAndTime.length)
    });
  };

  changeFullName = (e) => {
    this.setState({
      name: e.target.value
    });
  };

  changePhone = (e) => {
    this.setState({
      phone: e.target.value,
      showError: false
    });
  };

  changeEmail = (e) => {
    this.setState({
      email: e.target.value
    });
  };

  handleSubmit = () => {
    this.props.submitUserInfoForm(this.state.name, this.state.phone, this.state.email, this.state.pickupDateAndTime);
  };

  removeError = () => {
    this.setState({
      showError: false
    })
  };

  render() {
    return (
      <RemindMeLater
        {...this.props}
        changePickupDate={this.changePickupDate}
        changePickupTime={this.changePickupTime}
        changePickupDateAndTime={this.changePickupDateAndTime}
        changeFullName={this.changeFullName}
        changePhone={this.changePhone}
        changeEmail={this.changeEmail}
        handleSubmit={this.handleSubmit}
        removeError={this.removeError}
        name={this.state.name}
        phone={this.state.phone}
        showError={this.state.showError}
        email={this.state.email}
        pickupDate={this.state.pickupDate}
        pickupTime={this.state.pickupTime}
        pickupDateAndTime={this.state.pickupDateAndTime}
        title={this.state.title}
        ctaText={this.state.ctaText}
        blurb={this.state.blurb}
        customTitleUsed={this.state.customTitleUsed}
      />
    )
  }

  componentWillUnmount() {
    this.props.leaveUserInfoForm();
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitUserInfoForm: (name, phone, email, pickupDateAndTime) => dispatch(submitUserInfo(name, phone, email, pickupDateAndTime)),
    leaveUserInfoForm: () => dispatch(leaveUserInfoForm())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemindMeLaterContainer);

